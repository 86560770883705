import React from 'react';
import StoreButton from './StoreButton';

import GooglePlayLogo from 'assets/icons/google-play-logo.svg';
import styled from 'styled-components';
import { StoresLinks } from 'utils/constants/externalLinks';

const GooglePlayButton = ({ ...props }) => (
  <AppleStoreBadge
    onClick={() => window.open(StoresLinks.GooglePlay, '_system')}
  />
);

export default GooglePlayButton;

const AppleStoreBadge = styled(GooglePlayLogo)`
  width: 10.21875rem;
  height: 2.95206rem;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
  cursor: pointer;
`;
