import React from 'react';
import StoreButton from './StoreButton';

import AppleStoreLogo from 'assets/icons/apple-store-logo.svg';

import styled from 'styled-components';
import { StoresLinks } from 'utils/constants/externalLinks';

const AppleStoreButton = ({ ...props }) => (
  <AppleStoreBadge
    onClick={() => window.open(StoresLinks.AppleStore, '_system')}
  />
);

export default AppleStoreButton;

const AppleStoreBadge = styled(AppleStoreLogo)`
  width: 10.21875rem;
  height: 2.95206rem;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
  cursor: pointer;
`;
